import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoEN } from "../components/seo";
import { translations } from "../locales/wedding.js";
import EventsTypes from "../components/eventsTypes";
import background from "../assets/img/eventsTypes/wedding.svg";

const Wedding = () => {

  return (
    <Layout page="wedding" language="en">
      <main className=" pricing-page-container container">
        <EventsTypes
          background={background}
          text={translations.en.text}
          title={translations.en.title}
          textButton={translations.en.button}
          language={"en"}
        />
      </main>
    </Layout>
  );
};

export default Wedding;

export const Head = () => <SeoEN title="Digital Wedding Invitations Online | Design and send your invitations with Kolibird" description="With Kolibird, create and send your wedding invitations digitally. Customize your designs and surprise your guests with an ecological and practical alternative, sign up now at Kolibird and start designing your wedding invitations!" hrefLangs={[{href: "https://kolibird.app/invitaciones-digitales-boda/", lang: "es"}, {href: "https://kolibird.app/en/digital-wedding-invitations/", lang: "en"}]}/>;
